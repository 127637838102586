<template>
	<div id="body">
		<div class="fatherBox">
			<div class="headerBox">
				<p>代理列表</p>
				<el-button type="primary" class="btn" @click="dialogFormVisible = true, agentAdd = {}">+ 添加代理
				</el-button>
			</div>
			<!-- <div class="query">
        <div class="queryLeft">
          <div>代理账号：
            <el-input class="input" v-model="serchList.mechineName" size="medium" placeholder="请输入编号"></el-input>
          </div>
        </div>
        <div class="queryRight">
          <el-button @click="reset()">重置</el-button>
          <el-button type="primary" @click="agentList(serchList,1)">查询</el-button>
        </div>
      </div> -->
			<el-table :data="agentData" v-loading="loading">
				<el-table-column prop="agentId" label="代理ID" align="center" width="120"></el-table-column>
				<el-table-column prop="AgentName" label="代理名称" align="center"> </el-table-column>
				<el-table-column prop="RegisterMobile" label="联系电话" align="center"></el-table-column>
				<el-table-column prop="GuaName" label="APP昵称" align="center"></el-table-column>
				<el-table-column prop="percentage" label="代理分成比例(单位:%)" align="center">
					<template slot-scope="scope">
						<span>{{scope.row.percentage + '%'}}</span>
						<i class="el-icon-edit iconClick" @click="setScale(scope.row.agentId)"></i>
					</template>
				</el-table-column>
				<el-table-column prop="InsertTime" label="创建时间" align="center" width="150"></el-table-column>
			</el-table>
			<div class="block">
				<el-pagination @current-change="handleCurrentChange" :current-page="currpage" :page-size="pagesize"
					background layout="total, prev, pager, next, jumper" :total="CountNum" style="margin-top:15px">
				</el-pagination>
			</div>
		</div>
		<el-dialog title="添加代理" :visible.sync="dialogFormVisible" top="50px" :close-on-click-modal="false">
			<el-form :model="agentAdd" ref="articleForm" style="width:460px;margin:0 auto">
				<el-form-item label="请输入代理账号:">
					<el-input v-model="agentAdd.agent" style="width: 260px; float: left" placeholder="请输入代理账号"
						clearable>
					</el-input>
				</el-form-item>
				<el-form-item style="margin-top:5px">
					<el-button @click="dialogFormVisible = false">取消</el-button>
					<el-button type="primary" @click="Adds">添加</el-button>
				</el-form-item>
			</el-form>
		</el-dialog>
	</div>
</template>

<script>
	import {
		agentList, //代理列表接口
		agentAdd, //添加代理接口
		agentAdvShare //设置代理广告分成接口
	} from '@/api/adminApi.js'
	export default {
		data() {
			return {
				agentData: [], // 代理列表数据
				dialogFormVisible: false, // 添加代理弹窗
				loading: false, //加载框
				pagesize: 10, // 每页显示条目个数
				currpage: 1, // 初始页
				CountNum: 0, // 总条数
				serchList: {}, //搜索栏查询
				agentAdd: {} // 添加新的代理
			}
		},
		created() {
			this.agentList({}, 1) // 获取代理列表
		},
		methods: {
			reset() { //重置按钮
				this.serchList = {}
				this.agentList({}, 1)
			},
			agentList(serchJson, currpage) { // 代理列表
				this.loading = true
				const data = serchJson
				data["page"] = currpage //	当前页数
				data["limit"] = this.pagesize // 每页条数
				agentList(data).then(res => {
					this.CountNum = parseInt(res.data.CountNum) // 总条数
					this.agentData = res.data.List // 代理列表
					this.currpage = currpage //分页显示当前页
					this.loading = false
				}).catch(err => {
					console.log(err)
				})
			},
			Adds() { // 点击添加代理按钮
				if (!this.agentAdd.agent) {
					this.$message.error('代理账号不能为空')
				} else {
					const data = {
						account: this.agentAdd.agent
					}
					agentAdd(data).then(res => {
						if (res.status.code == 1) {
							this.$message({
								message: '添加成功',
								type: 'success'
							})
							this.dialogFormVisible = false //关闭添加代理弹窗
							this.agentList({}, this.currpage) // 刷新
						} else {
							this.$message.error(res.status.msg)
						}
					}).catch(err => {
						console.log(err)
					})
				}
			},
			setScale(id) { //设置代理分成比例
				this.$prompt('请输入提成比例：', '提示', {
					confirmButtonText: '确定',
					cancelButtonText: '取消',
					inputPattern: /^([0-9]{1,2}|100)$/,
					inputErrorMessage: '提成比例在0-100之间'
				}).then(({
					value
				}) => {
					let data = {
						percentage: value, //提成比例
						agentId: id, //代理id
					}
					agentAdvShare(data).then(res => {
						if (res.status.code == 1) {
							this.$message({
								type: 'success',
								message: '提成比例修改成功！'
							});
							this.agentList({}, 1) // 刷新代理列表
						} else {
							this.$message({
								type: 'error',
								message: res.status.msg
							});
						}
					}).catch(err => console.log(err))
				}).catch(() => {
					this.$message({
						type: 'info',
						message: '取消修改！'
					});
				});
			},
			handleCurrentChange(val) { // 分页
				this.agentList({}, val)
			}
		}
	}
</script>

<style lang="scss" scoped>
	.headerBox {
		display: flex;
		height: 52px;
		width: 98%;
		align-items: center;
		justify-content: space-between;
		border-bottom: 2px solid #e9e9e9;

		p {
			font-size: 16px;
			font-family: 萍方-简;
			font-weight: normal;
			color: #000000;
			margin-left: 32px;
		}

		.btn {
			display: flex;
			align-items: center;
			width: 125px;
			height: 32px;
			opacity: 1;
			border-radius: 2px;
		}
	}

	.query {
		width: 98%;
		align-items: center;
		display: flex;
		justify-content: space-between;
		padding-top: 20px;
		padding-left: 32px;

		.queryLeft {
			width: 80%;
			display: flex;

			div {
				width: 22%;
				text-align: left;

				.input,
				.select {
					width: 60%;
				}
			}
		}
	}
	
	.iconClick {
	  color: #409EFF;
	  margin-left: 3px;
	  cursor: pointer;
	}
</style>
